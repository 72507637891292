import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { Breadcrumbs, Hero } from "../../components/common";

import Subscription from "../../components/subscription";

import CardImage from "../../assets/images/card-image.jpg";

import "./post.css";

function BlogPostPage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Blog Post"
        path={location?.pathname}
      />

      <section className="mt-20 mb-6">
        <Hero
          subTitle="Vackground Blog"
          title="Creating A Sense of Wonder with Lindsay Hook"
        />
      </section>
      <section className="mb-16 flex items-center justify-center">
        <Breadcrumbs
          lists={[
            {
              title: "Home",
              to: "/",
            },
            {
              title: "All Design Assets",
              to: "/categories",
            },
          ]}
        />
      </section>
      <section className="mb-40 max-w-5xl m-auto">
        <div className="mb-16">
          <img
            className="w-full"
            style={{ height: 500 }}
            alt="blog-featured-image"
            src={CardImage}
            loading="lazy"
          />
        </div>
        <div className="max-w-3xl m-auto dev-vg-blog-post">
          <p>
            Wow! What a stunning portfolio Lindsay Hook has! Her illustrations
            are beautiful, and she uses them in such a striking way within her
            layouts. It’s amazing to view her work and explore her rich,
            inspiring color palettes. And it was equally amazing to learn more
            about Lindsay and how she creates these stunning patterns which
            evoke a sense of wonder.
          </p>
          <h2>Share a bit about your life</h2>
          <p>
            I am a freelance brand designer, mother of two, and a California
            native. I went to college and studied graphic design and studio art,
            with a focus in printmaking and ceramics. I was a brand designer for
            years and currently do freelance work in-between chasing my two
            littles. I started doing surface pattern design after years of
            dreaming about trying it. Now it is a focus of my free time and I
            love the direction it’s heading.
          </p>
          <h2>A Sense of Wonder</h2>
          <p>
            I love exploring motifs that bring a little sense of wonder and
            mystery. Adventure and literary stories are my daily inspiration.
            <br />
            <br />I am influenced by my background in traditional printmaking
            techniques, although I am fully digital in my execution. I use both
            Procreate and Illustrator to create my designs.
          </p>
        </div>
      </section>
      <section className="mb-24">
        <Subscription />
      </section>
    </Layout>
  );
}

BlogPostPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default BlogPostPage;
